<template>
  <div>
    <TitleWrapper
      :filter-option="false"
      title="PERSONAL_INFO"
      tooltip-title="PERSONAL_INFO"
      :display-breadcrumb="true"
    />
    <div
      class="min-h-screen bg-bg-color-white pt-7 mt-7 px-3 md:px-7 border rounded-t-lg border-border-color-lighter"
    >
      <div class="flex lg:gap-3 flex-col lg:flex-row">
        <InputBox
          type="text"
          name="First Name"
          title="First Name"
          label="First Name"
          placeholder="First Name"
          class="flex-1"
        />
        <InputBox
          type="text"
          name="Last Name"
          title="Last Name"
          label="Last Name"
          placeholder="Last Name"
          class="flex-1"
        />
      </div>
      <div class="flex lg:gap-3 flex-col lg:flex-row">
        <UiSingleSelect title="Gender" :options="genderArray" class="flex-1" />
        <UiSingleSelect title="BL_GROUP" :options="bloodGroupArray" class="flex-1" />
      </div>
      <div class="flex lg:gap-3 flex-col lg:flex-row">
        <UiDatePicker title="DOB" class="flex-1" />
        <UiSingleSelect title="Religion" :options="religion" class="flex-1" />
      </div>
      <InputBox
        type="text"
        name="Nationality"
        title="Nationality"
        label="Nationality"
        placeholder="American"
        class="flex-1"
      />
      <button
        class="mt-5 focus:outline-none focus:shadow-md ltr:text-base rtl:text-sm text-white font-rubik flex w-full md:w-40 py-2 justify-center primary-button"
      >
        Save Changes
      </button>
    </div>
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import InputBox from '@components/UiElements/UiInputBox.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import UiDatePicker from '@components/UiElements/UiDatePicker.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    TitleWrapper,
    InputBox,
    UiSingleSelect,
    UiDatePicker,
  },
  data() {
    return {
      genderArray: ['male', 'female'],
      bloodGroupArray: ['A+', 'A-', 'B+', 'B-', 'O+', 'O-', 'AB+', 'AB-'],
      religion: ['Islam', 'Christianity', 'Hinduism'],
    }
  },
  mounted() {
    this.setRightbarData()
  },
  methods: {
    ...mapActions('layout', ['setRightbarContent']),
    setRightbarData(length = 0) {
      const rightBarContent = {
        header: {
          title: 'Settings',
        },
        profileInfo: [
          {
            isUploadButton: true,
            imageUrl: this.currentStudent?.profile?.image || null,
          },
        ],
        bargraph: [
          {
            barGraphTitle: this.$t('rightBar.Active Hours'),
            barGraphList: [
              { value: 2, color: '#267C26', tooltip: 'BIO' },
              { value: 12, color: '#267C26', tooltip: 'CHEM' },
              { value: 10, color: '#267C26', tooltip: 'PHY' },
              { value: 18, color: '#267C26', tooltip: 'MATH' },
              { value: 6, color: '#267C26', tooltip: 'ENG' },
              { value: 16, color: '#267C26', tooltip: 'URD' },
              { value: 4, color: '#267C26', tooltip: 'PAK' },
            ],
          },
        ],
      }
      this.setRightbarContent(rightBarContent)
    },
  },
}
</script>
